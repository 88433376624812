<template>
  <div>
    <div class="mb-10">
      <h1 title="Login" class="mb-0">Login</h1>
      <p class="muted--text">Please login to continue</p>

      <v-alert v-if="undefined != err.message" type="error" class="mb-8" outlined dense>{{
        err.message
      }}</v-alert>
    </div>
    <form @submit.prevent="loginSubmit">
      <div class="mb-3">
        <v-text-field
          type="email"
          outlined
          label="Username"
          hide-details
          prepend-inner-icon="mdi-account"
          class="dt-text-field"
          v-model="email"
          required
        ></v-text-field>
      </div>
      <div>
        <v-text-field
          type="password"
          outlined
          label="Password"
          hide-details
          prepend-inner-icon="mdi-lock"
          class="dt-text-field"
          v-model="password"
          required
        ></v-text-field>
      </div>
      <div class="text-right mb-5">
        <router-link :to="{ name: 'auth.forgot.password' }">
          <small class="muted--text">Forgot Password</small>
        </router-link>
      </div>
      <div class="text-center">
        <v-btn block x-large :disabled="loading" color="primary" exact type="submit">
          <span v-text="loading ? 'Logging in' : 'Login'"></span>
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import hondaLogo from "@/assets/honda_logo.png";
import { mapGetters, mapActions } from "vuex";
import { getCookie, removeCookie, moveLocalStorageTokenToCookie } from "@/core/cookie";

export default {
  data() {
    return {
      hondaLogo,
      email: null,
      password: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      err: "auth/GET_ERR",
      user: "auth/GET_USER",
    }),
  },
  mounted() {
    if (localStorage.user) {
      if (this.$router.currentRoute.query?.returnUrl) {
        this.redirectToCDCPage();

        return;
      }

      this.$router.push({
        name: "home",
      });
    }
  },
  methods: {
    ...mapActions({
      authLogin: "auth/login",
    }),
    async loginSubmit() {
      if(this.loading){
        return
      }

      this.loading = true; 

      await this.generateCsrfToken();
      
      const response = await this.authLogin({
        email: this.email,
        password: this.password,
      });

      this.loading = false;

      if(response === "mfa"){
        this.$router.push({
            name: "auth.mfa",
            params: { email: this.email }
        });

        return
      }

      // this.loading = false;

      if (!this.err.message) {
        const loginUser = JSON.parse(this.user);
        if (loginUser.is_temp_password == 1) {
          this.$router.push({
            name: "auth.force.reset.password",
          });
          return;
        }

        if (this.$router.currentRoute.query?.returnUrl) {
          this.redirectToCDCPage();

          return;
        }

        this.$router.push({
          name: "home",
        });
      }
    },
    redirectToCDCPage() {
      let url = this.$router.currentRoute.query?.returnUrl;
      url = new URL(url);
      const queryString = new URLSearchParams(url.search);

      this.$router.push({ name: "cdcs", query: { id: queryString.get("id") } });
    },
    generateCsrfToken() {

      let apiBaseUrl = process.env.VUE_APP_API_BASE_URL;

      axios({
        method: 'GET',
        baseURL: 'https://apidealerportal.hondaphil.com/',
        url: 'sanctum/csrf-cookie',
        headers: {'X-Requested-With': 'XMLHttpRequest'},
        withCredentials: true
      })
        .then(() => {})
        .catch(error => {
          console.error(error.response.message)
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.btnLogin {
  background-color: #00aad8 !important;
  color: white !important;
}

.bg-white {
  background: #fff !important;
}
</style>
